'use client';
// Re-export `design-system-context` and `global-styles-provider code
// for consumption within other projects. The idea is for `design-system` to supply
// the exports for ease of consumption, but for them to live
// in a separate packages so that package can be hoisted during installs.
// The hoisting ensures that the context object is `===` across different
// `design-system` versions within the consuming app, so long as the `design-system`
// versions point to the same `design-system-context` and `global-styles-provider` version.
// Since the packages should be updated very infrequently,
// this re-export mechanism should provide consumers with an easy
// way to share `===` context objects across `design-system` versions.
export {
  DesignSystemProvider,
  useDesignSystem,
} from '@zapier/design-system-context';

export {
  GlobalStylesProvider,
  PLATFORM_COOKIE,
  REBRAND_COOKIE,
  useTheme,
} from '@zapier/global-styles-provider';

// Utilities
export * from './ts-types';
export * from './theme';
export * from './hooks';
export * from './utils/formatAriaAttrKeys';
export * from './utils/createUniqueCustomProperty';
export * from './utils/heading-level-context';

// Display components
export * from './components/display/Accordion';
export * from './components/display/Alert';
export * from './components/display/Article';
export * from './components/display/Avatar';
export * from './components/display/Badge';
export * from './components/display/Carousel';
export * from './components/display/FloatingBox';
export * from './components/display/Heading';
export * from './components/display/Icon';
export * from './components/display/Img';
export * from './components/display/LoadableImg';
export * from './components/display/Modal';
export * from './components/display/ProgressBar';
export * from './components/display/SkeletonLoader';
export * from './components/display/Spinner';
export * from './components/display/Surface';
export * from './components/display/TagLabel';
export * from './components/display/Text';
export * from './components/display/Tooltip';
export * from './components/display/TooltipWrapper';
export * from './components/display/YouTubeVideo';

// Form Components
export * from './components/forms/BooleanInput';
export * from './components/forms/CopyTextInput';
export * from './components/forms/DateRangePicker';
export * from './components/forms/Dropdown';
export * from './components/forms/Field';
export * from './components/forms/FloatingMenu';
export * from './components/forms/FormLabel';
export * from './components/forms/Input';
export * from './components/forms/Menu';
export * from './components/forms/TextInput';
export * from './components/forms/ToggleSwitch';
export { default as Typeahead } from './components/forms/Typeahead';
export * from './components/forms/SubscriptionForm';

// Layout Components
export * from './components/layout/ContentSection';
export * from './components/layout/FAQ';
export * from './components/layout/Grid';
export * from './components/layout/Margin';
export * from './components/layout/Spacer';
export * from './components/layout/CTA/CTACentered';
export * from './components/layout/CTA/CTAFilled';
export * from './components/layout/CTA/CTAStandard';
export * from './components/layout/Hero/HeroFeatures';
export * from './components/layout/Hero/HeroStandard';
export * from './components/layout/Hero/HeroColorBlock';
export * from './components/layout/TestimonialSolo';
export * from './components/layout/TestimonialMultipleColumn';
export * from './components/layout/TestimonialCarousel';
export * from './components/layout/OneColumnBlock';
export * from './components/layout/Block';
export * from './components/layout/BlockGrid';
export * from './components/layout/TwoColumnRiver';
export * from './components/layout/LogoBar';

// Navigation Components
export * from './components/navigation/BackButton';
export * from './components/navigation/Breadcrumbs';
export * from './components/navigation/Button';
export * from './components/navigation/ButtonGroup';
export * from './components/navigation/ButtonNav';
export * from './components/navigation/IconButton';
export * from './components/navigation/Indicator';
export * from './components/navigation/InteractiveTag';
export * from './components/navigation/Link';
export * from './components/navigation/LoadMoreBar';
export * from './components/navigation/NumberButton';
export * from './components/navigation/PageNavigation';
export * from './components/navigation/Paginator';
export * from './components/display/Table';
export * from './components/navigation/Tabs';
export * from './components/display/VisuallyHidden';
export * from './components/navigation/BrandButton';
export * from './components/navigation/DarkBackgroundButton';
export * from './components/display/ZinniaFonts';
export * from './components/navigation/DarkBackgroundIconButton';

// Design System Patterns
export * from './components/patterns/PolicyModal';
export * from './components/patterns/ServiceDropdown';
export * from './components/patterns/ServiceIcon';
export * from './components/patterns/ServiceIcons';
export * from './components/patterns/ServiceIconShell';
export * from './components/patterns/ServiceTypeahead';
export * from './components/patterns/ZapCard';
export * from './components/patterns/ZapCardSection';
export * from './components/patterns/ZapierLogo';
export * from './components/patterns/ZapierLogoSymbol';
export * from './components/patterns/types';

// BuilderNav
export * from './components/Nav';
